// import '../scss/styles.scss'

import { popups } from './modules/popups'
import { tabs } from './modules/tabs'
import { phones } from './modules/phones'
import { forms } from './modules/forms'
import { lazyload } from './modules/lazyload'
import { carousels } from './modules/carousels'
import { header } from './modules/header'
import { loaderForms } from './modules/loader-forms'

window.GetHooked = {}

const afterLoad = () => {
    popups.init()
    tabs.init()
    phones.init()
    forms.init()
    lazyload.init()
    carousels.init()
    header.init()
    loaderForms.init()
}

document.addEventListener('DOMContentLoaded', afterLoad)

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
      console.log("HMR")
    })
}
