'use strict'

export const popups = (() => {
    const _modals = document.querySelectorAll('.modal')
    const _backdrop = document.querySelector('.modal-backdrop')

    const moveToEnd = () => {
        _modals.forEach(modal => {
            document.body.appendChild(modal)
        })
    }

    const checkBackdrop = () => {
        if(!_backdrop) return

        if(Array.from(_modals).some(modal => modal.classList.contains('show'))) {
            const _scrollY = window.scrollY
            const _scrollBarWidth = window.innerWidth - document.documentElement.clientWidth

            // Show backdrop
            _backdrop.classList.add('show')

            // Add class to body
            document.body.classList.add('modal-open')

            // Compensate vertical scroll
            document.body.style.top = -_scrollY + 'px'

            // Compensate scrollbar width
            if(document.dir == 'rtl') {
                document.body.style.paddingLeft = _scrollBarWidth + 'px'
            } else {
                document.body.style.paddingRight = _scrollBarWidth + 'px'
            }

            // Stop all sliders
            if(window.GetHooked.stopSliders) {
                window.GetHooked.stopSliders()
            }

        } else {
            const _scrollY = document.body.style.top

            // Hide backdrop
            _backdrop.classList.remove('show')

            // Remove class from body
            document.body.classList.remove('modal-open')

            // Remove style top and padding from body
            document.body.style.top = ''
            if(document.dir == 'rtl') {
                document.body.style.paddingRight = ''
            } else {
                document.body.style.paddingRight = ''
            }

            // Restore scroll top position
            window.scrollTo({
                top: -1 * parseInt(_scrollY),
                behavior: 'instant'
            })

            // Resume all sliders
            if(window.GetHooked.resumeSliders) {
                window.GetHooked.resumeSliders()
            }
        }
    }

    const setInputValues = (button, popup) => {
        // Check for input with a name from dataset
        for (const inputName in button.dataset) {
            const _input = popup.querySelector(`[name="${inputName}"]`)
            if (_input) {
                _input.value = button.dataset[inputName]
            }
        }
    }

    const clickButton = (event) => {
        event.preventDefault()
        event.stopPropagation()

        const _button = event.target

        if (!_button) return
        
        const _popupId = _button.dataset.popup

        if (!_popupId) return
        
        const _popup = document.getElementById(_popupId)

        if(!_popup) return
        
        setInputValues(_button, _popup)
        _popup.classList.add('show')

        checkBackdrop()
    }

    const closePopup = (event) => {
        const _button = event.target

        if (!_button) return
        
        const _popup = _button.closest('.modal')

        if (!_popup) return
        
        _popup.classList.remove('show')

        checkBackdrop()
    }

    const initButtons = () => {
        const _buttons = document.querySelectorAll('[data-popup]')
        const _closeButtons = document.querySelectorAll('[data-popup-close]')

        _buttons.forEach(button => {
            button.addEventListener('click', clickButton)
        })

        _closeButtons.forEach(button => {
            button.addEventListener('click', closePopup)
        })

        _backdrop.addEventListener('click', () => {
            if(!_backdrop.classList.contains('show')) return

            const _openPopups = document.querySelectorAll('.modal.show')

            _openPopups.forEach(popup => {
                popup.classList.remove('show')
            })

            checkBackdrop()
        })
    }

    const init = () => {
        moveToEnd()
        initButtons()

        window.GetHooked.initModalButtons = initButtons
    }

    return {
        init
    }
})()