'use strict'

import intlTelInput from 'intl-tel-input'

export const phones = (() => {
    const initPhones = () => {
        const phoneInputs = document.querySelectorAll('.intl-tel-input')

        if(phoneInputs.length == 0) return

        phoneInputs.forEach(phoneInput => {
            let options = {
                initialCountry: 'nl',
                onlyCountries: ['nl'],
                // geoIpLookup: function(callback) {
                //     fetch("https://ipapi.co/json")
                //         .then(function(res) { return res.json(); })
                //         .then(function(data) { callback(data.country_code); })
                //         .catch(function() { callback(); });
                // },
                nationalMode: true,
                separateDialCode: true,
                utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@23.0.4/build/js/utils.js',
                fixDropdownWidth: false,
                strictMode: true,
            }

            const dataName = phoneInput.dataset.name
            if(dataName) options.hiddenInput = () => ({
                phone: dataName,
            })

            const iti = intlTelInput(phoneInput, options)

            //  Copy initial value to hidden input
            if(iti.hiddenInput) iti.hiddenInput.value = iti.getNumber()

            // In case of hidden fields we need to remove country code field
            if(iti.hiddenInputCountry) iti.hiddenInputCountry.remove()

            phoneInput.addEventListener('input', () => {
                // console.log(iti, iti.hiddenInput);
                if(iti.hiddenInput) {
                    iti.hiddenInput.value = iti.getNumber()
                }

                if(iti.isValidNumber()) phoneInput.setCustomValidity('')
                else phoneInput.setCustomValidity('Error')
            })
        })
    }

    const init = () => {
        initPhones()

        window.GetHooked.initPhones = initPhones
    }

    return {
        init
    }
})()
