'use strict'

import * as FilePond from "filepond"
import FilePondPluginFileEncode from 'filepond-plugin-file-encode'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'

// Forms that shouldn't be formatted.
// mc-embedded-subscribe-form = Mailchimp form in footer
const excludedFormNames = ["mc-embedded-subscribe-form"]

export const forms = (() => {
    const ifCheck = input => {
        if(!input) return

        const _check = input.closest('.form-check-label')

        if(!_check) return

        if(input.checked) {
            _check.classList.add('checked')
        } else {
            _check.classList.remove('checked')
        }
    }

    const initChecks = () => {
        const _checks = document.querySelectorAll('.form-check-label')

        _checks.forEach(check => {
            const _input = check.querySelector('input')

            ifCheck(_input)

            check.addEventListener('click', () => {
                const _input = check.querySelector('input')

                if(!_input) return

                const _name = _input.name
                const _inputs = document.querySelectorAll('[name="' + _name + '"]')

                _inputs.forEach(input => ifCheck(input))
            })
        })
    }

    const checkFormValidity = form => {
        const _submitButton = form.querySelector('[type="submit"]')

        if(!_submitButton) return

        if(form.checkValidity()) {
            _submitButton.removeAttribute('disabled')
            return true
        }

        _submitButton.setAttribute('disabled', 'disabled')
        return false
    }

    const initValidations = () => {
        const _forms = document.querySelectorAll('form[novalidate]:not([validation-inited])')

        _forms.forEach(form => {
            if (excludedFormNames.includes(form.name)) {
                return;
            }

            form.addEventListener('input', () => {
                checkFormValidity(form)
            })
            form.addEventListener('paste', () => {
                checkFormValidity(form)
            })

            form.addEventListener('submit', event => {
                if(!checkFormValidity(form)) {
                    event.preventDefault()
                    event.stopPropagation()

                    // scroll to first invalid field
                    const _firstInvalidField = form.querySelector(':invalid')

                    if(_firstInvalidField) {
                        _firstInvalidField.scrollIntoView({
                            behaviour: 'smooth',
                            block: 'center'
                        })
                    }

                    form.classList.remove('was-validated')

                } else {
                    form.classList.add('was-validated')
                }

            }, false)

            form.setAttribute('validation-inited', '')
        })
    }

    const initForms = () => {
        initChecks()
        initValidations()
    }

    const reinitForm = form => {
        if(!form) return

        if(!form.hasAttribute('validation-inited')) return

        const _submitButton = form.querySelector('[type="submit"]')

        if(!_submitButton) return

        form.removeAttribute('validation-inited')
        initForms()
        checkFormValidity(form)
    }

    const initFileInputs = () => {
        const _fileInputs = document.querySelectorAll('input[type="file"]')

        FilePond.registerPlugin(
            FilePondPluginFileEncode, // encodes the file as base64 data
            FilePondPluginFileValidateSize, // validates the size of the file
            FilePondPluginImageExifOrientation, // corrects mobile image orientation
            FilePondPluginImagePreview // previews dropped images
        )

        _fileInputs.forEach(input => {
            const pond = FilePond.create(input)

            console.log(pond);
        })
    }

    const init = () => {
        // initFileInputs()
        initForms()

        window.GetHooked.initForms = initForms
        window.GetHooked.reinitForm = reinitForm
        window.GetHooked.initFileInputs = initFileInputs
    }

    return {
        init,
    }
}) ()
