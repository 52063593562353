'use strict'

import Splide from '@splidejs/splide'

export const carousels = (() => {
    const initCarousels = () => {
        const splides = document.querySelectorAll('.splide')

        if(splides.length == 0) return

        splides.forEach(splide => {
            new Splide( splide ).mount();
        })
    }

    const init = () => {
        initCarousels()

        window.GetHooked.initCarousels = initCarousels
    }

    return {
        init
    }
})()
