'use strict'

export const lazyload = (() => {
    const setData = item => {
        if(item.dataset.src) {
            item.src = item.dataset.src
            delete item.dataset.src

            const picture = item.closest('picture')
            
            if(!picture) return

            const sources = picture.querySelectorAll('source[data-srcset]')

            sources.forEach(source => {
                source.srcset = source.dataset.srcset
                delete source.dataset.srcset
            })
        }
    }
    
    const init = () => {
        const _images = document.querySelectorAll('[data-src]')

        if('IntersectionObserver' in window) {
            const _lazyloadObserver = new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {         
                    if(entry.isIntersecting) {
                        const _item = entry.target

                        setData(_item)
                        observer.unobserve(_item)
                    }
                })
            })

            _images.forEach(_item => _lazyloadObserver.observe(_item))

        } else {
            // If browser dosn't support IntersectionObserver just copy data-src to src
            _images.forEach(_item => setData(_item))
        }

        window.GetHooked.initLazyloads = init
    }

    return {
        init
    }
}) ()