'use strict'

export const header = (() => {
    const init = () => {
        const positiveIcon = document.querySelector('#speak-icon');
        const header = document.querySelector('header');

        if (positiveIcon) {
            header.classList.remove('header--transparent');
        }
    }

    return {
        init
    }
})()
