'use strict'

export const loaderForms = (() => {
    const init = () => {
        const loaderFormContainers = document.querySelectorAll('.loader-form');

        if (loaderFormContainers) {
            loaderFormContainers.forEach(loaderFormContainer => {
                loaderFormContainer.addEventListener('submit', (e) => {
                    e.preventDefault();

                    const searchButton = loaderFormContainer.querySelector('button.btn');
                    const searchImage = loaderFormContainer.querySelector('img');

                    searchButton.remove();
                    searchImage.classList.remove('!hidden');

                    setTimeout(() => {
                        loaderFormContainer.submit();
                    }, 1500)
                });
            });
        }
    }

    return {
        init
    }
})()
