'use strict'

export const tabs = (() => {
    const tabBlocks = document.querySelectorAll('[data-tabs]')

    const init = () => {
        tabBlocks.forEach(tabBlock => {
            const _tabs = tabBlock.querySelectorAll('[data-tab]')
            const _panels = tabBlock.querySelectorAll('[data-panel]')

            _tabs.forEach(tab => {
                tab.addEventListener('click', () => {
                    if(tab.classList.contains('active')) return

                    const _id = tab.dataset.tab

                    if(!_id) return

                    const _panelToShow = document.querySelector('[data-panel="' + _id + '"]')

                    if(!_panelToShow) return

                    _tabs.forEach(tab => {
                        tab.classList.remove('active')
                    })

                    _panels.forEach(panel => {
                        panel.classList.remove('active')
                    })

                    tab.classList.add('active')
                    _panelToShow.classList.add('active')

                    const _firstInput = _panelToShow.querySelector('input[name], input[data-name]')

                    if(_firstInput) _firstInput.focus()
                })
            })

            _tabs[0].classList.add('active')
            _panels[0].classList.add('active')
        })
    }

    return {
        init
    }
})()
